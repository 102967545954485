import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Heading from './components/heading/Heading';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import Products from './pages/products/Products';
import Team from './pages/team/Team';
import Contact from './pages/contact/Contact';
import Revenue from './pages/admin/Revenue';
import Profile from './pages/admin/profile/Profile';
import Dashboard from './pages/admin/dashboard/Dashboard';
import Logout from './pages/admin/Logout';
import ProtectedRoutes from './utils/ProtectedRoutes';
import { UserAuthProvider } from './utils/UserAuthProvider';
import Downloads from './pages/admin/datamanagement/DataManagement';
import DataStructure from './pages/admin/datamanagement/DataStructure';
import { loadListFromFS } from './services/dropdownService';
import { AlertProvider } from './utils/AlertContext';
// eslint-disable-next-line no-unused-vars
import SharedAlert from './utils/SharedAlert';
import Plants from './pages/admin/plants/Plants';

function App() {
  useEffect(() => {
    // Putting all other components on wait till the dropdown lists
    // are loaded.
    async function fetchData() {
      await loadListFromFS();
    }
    fetchData();
  }, []);

  return (
    <Router>
      <UserAuthProvider>
        <AlertProvider>
          <div className='grid-container'>
          <Heading />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="*" element={<Home />} />
            <Route element={<ProtectedRoutes allowedRoles={['ADMIN']} />}>
              <Route path="products" element={<Products />} />
              <Route path="plants" element={<Plants />} />
              <Route path="datamanagement" element={<Downloads />} />
              <Route path="datastructure" element={<DataStructure />} />
              <Route path="revenue" element={<Revenue />} />
              <Route path="dashboard" element={<Dashboard />} />
            </Route>
            {false && <Route exact path="Products" element={<Products />} />}
            <Route exact path="Team" element={<Team />} />
            <Route exact path="Contact" element={<Contact />} />
            <Route element={<ProtectedRoutes allowedRoles={['ADMIN', 'GUEST']} />}>
              <Route path="Profile" element={<Profile />} />
              <Route path="Logout" element={<Logout />} />
            </Route>
          </Routes>
          <Footer></Footer>
          </div>
        </AlertProvider>
      </UserAuthProvider>
    </Router>
  );
}

export default App;
