// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserSessionPersistence, createUserWithEmailAndPassword, signInWithRedirect, getRedirectResult, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZ8hWm1ygz0x5ezen7iNzTzeX_F2bWz-w",
  authDomain: "narbhavi-4229f.firebaseapp.com",
  projectId: "narbhavi-4229f",
  storageBucket: "narbhavi-4229f.appspot.com",
  messagingSenderId: "617856191071",
  appId: "1:617856191071:web:e69472fb2b6cdbf6f629ea",
  measurementId: "G-Q2BYHTFJ0Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestore = getFirestore();
export const db = getFirestore(app);
export const authenticate = getAuth(app);
export const persistence = setPersistence;
export const sessionPersistence = browserSessionPersistence;
export const signByRedirect = signInWithRedirect;
export const fetchRedirectResult = getRedirectResult;
export const provider =  new GoogleAuthProvider();
export const addUser = createUserWithEmailAndPassword;
export const storage = getStorage(app);