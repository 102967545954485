import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './locales/en/translation.json'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: true,
        fallbackLng: 'en',
        lng: 'en',
        resources: {
            en: {
              translation: en // English translations
            }
        },        
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    })