import dataService from "./data.service"

const dropDownList = {};

const dropDownNames = {
    plantType: true,
    classification: true,
    surveyNumber: true,
    blockNumber: true,
    waterFrequency: true,
    harvestFrequency: true,
    pruningFrequency: true,
    plantStatus: true,
    fertigationFrequency: true
};

// The below is the way to add data in firestore.
// Before that you need to create docId with your dropdown list name and then pass over here.
// this.addToList("plantType",{label:"Shrubs", value:"shrubs"});

/**
 * Fetches the list options
 * @param {string} listName - collection name 
 * @returns {object} - contains label and value
 */
const loadListFromFS = async () => {
    try {
        const listPromises = Object.keys(dropDownNames).map((key) => {
            return dataService.getList(key);
        });
        const lists = await Promise.all(listPromises);

        lists.forEach((list, index) => {
            dropDownList[Object.keys(dropDownNames)[index]] = list;
        });

        Object.freeze(dropDownList);
    } catch (error) {
        console.log(error.name, error.message);
    }
};

export { dropDownNames, dropDownList, loadListFromFS };