import React, { Component } from 'react';
import { COLLECTION_LIST, downloadCsv } from '../../../utils/Collections';

export default class DataManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: '',
            options: [],
            disabled: true
        };
        this.setCollectionsData = this.setCollectionsData.bind(this);
        this.downloadCsvFile = this.downloadCsvFile.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);        
    }

    /**
     * Set the state with collection list
     * @param {object} data collection list
     */
    setCollectionsData = (data) => {
        const options = data.map((option) => {
            return <option key={option.name} value={option.cname}>{option.name}</option>;
        });
        this.setState({ options });
    }

    downloadCsvFile = (collection_name) => {
        downloadCsv(this.state.selectedOption);
    }

    /**
     * Enable or disable the download and upload csv option
     * based on the value selected.
     * @param {object} event 
     */
    handleDropdownChange = (event) => {
        const selectedOption = event.target.value;
        console.log(event.target.value);
        this.setState({ selectedOption });
        this.setState({ disabled: (selectedOption !== "") ? false : true });
    }

    componentDidMount() {
        this.setCollectionsData(COLLECTION_LIST); // Set collection list
    }

    render() {
        const { options } = this.state;
        return (
            <div className='main container-fluid gx-4'>
                <h2 className='mt-3 mb-3'>Data Management</h2>
                <form className="row">
                    <div className="mb-3">
                        <div className="col-md-3">
                            <label className="fw-bold">Collection List</label>
                            <select className="form-select mt-2" value={this.state.selectedOption} onChange={this.handleDropdownChange} aria-label="Default select example" key="">
                                <option value="" defaultValue="">Select a Collection</option>
                                {options}
                            </select>
                        </div>
                        <div className="col-md-3 mt-2">
                            <button type="button" className="btn btn-primary mb-3" aria-label="Download CSV" onClick={this.downloadCsvFile} disabled={this.state.disabled}>Download CSV</button>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="input-group">
                                <input type="file" className="form-control col-md-3" id="csvupload" disabled={this.state.disabled}/>
                                <label className="input-group-text" htmlFor="csvupload" aria-label="Upload CSV">Upload CSV</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
