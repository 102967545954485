import React, { Component } from 'react'

export default class Revenue extends Component {
    render() {
        return (
            <div className='main container-md'>
                <h2 className='mb-0 mt-3 mb-3'>Revenue</h2>
                <form className="g-3 row">
                    
                </form>
            </div>
        )
    }
}
