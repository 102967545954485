import { db } from "../config/config";
import { collection, orderBy, doc, getDoc, getDocs, query, where, addDoc, updateDoc, deleteDoc, Timestamp } from "firebase/firestore";

// import { CallBack } from "csvtojson/v2/Converter";

// Add all the collection in the firestore here
// There are no web libraries available at this time
// to get the collection list
export const COLLECTION_LIST = [
  { name: 'User Accounts', cname: 'user_account' },
  { name: 'Plants', cname: 'plants' }]


/**
 * This deletes all the records in the collection.
 * Should be used carefully. Only for development purpose.
 * @param {string} collection_name Name of the collection 
 */
export const deleteAllRecords = (async (collection_name) => {
  const querySnapshot = await getDocs(collection(db, collection_name));

  querySnapshot.forEach(async (doc) => {
    await deleteDoc(doc.ref);
  })
});


/**
 * Delete all the marked records which has been marked for deleted more than the passed days.
 * @param {string} collection_name Name of the collection
 * @param {number} days Delete the records which has been updated more than the passed days.
 */
export const deleteMarkedRecords = (async (collection_name, days) => {
  try {
    const daysAgo = Timestamp.fromMillis(Date.now() - days * 24 * 60 * 60 * 1000);
    const queryRef = await query(collection(db, collection_name), where('toDelete', '==', true), where('updatedOn', '<=', daysAgo));
    const querySnapshot = await getDocs(queryRef);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    })
  } catch (error) {
    console.error(error);
  }
});

/**
 * Mark the records to be deleted. These records will be purged in a batch
 * periodically.
 * @param {string} collection_name Name of the collection
 * @param {object} idArray Array of record id's  
 */
export const markRecordsForDeletion = (async (collection_name, idArray) => {
  const queryRef = await query(collection(db, collection_name), where('id', 'in', idArray));
  const querySnapshot = await getDocs(queryRef);

  if (!querySnapshot.empty) {
    querySnapshot.forEach(async (doc) => {
      try {
        // Update the field in the document
        await updateDoc(doc.ref, { toDelete: true });
        console.log("Field with doc.id " + doc.id + " is successfully marked for deletion!");
      } catch (error) {
        console.error("Error updating field:", error);
      }
    })
  }
});


/**
 * Add a record to the passed collection name.
 * Check whether the fields are matching before passing.
 * @param {string} collection_name 
 * @param {object} data 
 */
export const addNewRecord = (async (collection_name, data) => {
  // First check whether the record exists
  // Second check whether the keys are same and equal

  const docsRef = await collection(db, collection_name);
  const a = doc(db, collection_name, "id");
  const docSnap = await getDoc(a);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  const dataQuery = query(docsRef, where('id', '==', data.id));
  let querySnapshot = {};

  querySnapshot = await getDocs(dataQuery);

  if (querySnapshot.docs.length > 0) {
    const docRef = await addDoc(collection(db, collection_name), data)
      .then(() => {
        console.log('Data added to Firestore!');
      })
      .catch((error) => {
        console.error('Error adding data to Firestore: ', error);
      });
  } else {
    const docRef = await addDoc(collection(db, collection_name), data)
      .then(() => {
        console.log('Data added to Firestore!');
      })
      .catch((error) => {
        console.error('Error adding data to Firestore: ', error);
      });
  }
})

/**
 * Returns all the data from the collection
 * @param {string} collection_name The collection name 
 * @returns {object} collection data
 */
const getAllDataFromCollection = (async (collection_name) => {
  try {
    let data = [], csvData;
    if (collection_name) {
      console.log("Collection name: " + collection_name);
      const querySnapshot = await getDocs(collection(db, collection_name));

      try {
        data.push(querySnapshot.docs[0].data());
      } catch (error) {
        console.log(error)
      }

      csvData = jsonToCsv(data);
      // console.log(JSON.stringify(querySnapshot.docs[0].data()));
      return csvData;
    }
  } catch (error) {
    console.error(error);
  }
});


export const setDataInCollection = (async (collection_name, data) => {
  await db.collection(collection_name).add(data)
    .then(() => {
      console.log('Data added to Firestore!');
    })
    .catch((error) => {
      console.error('Error adding data to Firestore: ', error);
    });
})

/**
 * Converts JSON data in an array to CSV data
 * @param {object} json JSON data
 * @returns {string} csv CSV data
 */
const jsonToCsv = (json) => {
  console.log(json)
  const items = json;
  const replacer = (key, value) => (value === null ? '' :
    (typeof value === 'object' ? JSON.stringify(value).replace(/,/g, '|').replace(/"|\{|\}/g, '') : value)); // handle null values
  const header = Object.keys(items[0]);
  const csv = [
    header.join(','), // create CSV header row
    ...items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(','),
    ), // create CSV data rows
  ].join('\r\n');
  return csv;
};

/**
 * Download the CSV data received in the given filename.
 * @param {object} csv 
 * @param {string} filename 
 */
export const downloadCsv = (collection_name) => {
  let csvData = getAllDataFromCollection(collection_name);
  csvData.then(data => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', collection_name + ".csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }).catch(error => {
    console.error('Error:', error);
  });
};
