/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import SharedAlert from '../../utils/SharedAlert';
import UserAuthContext from '../../utils/UserAuthProvider';
import './heading.css';
import logo from './narbhavi.svg';
import { ReactComponent as ProfileUser } from './profile-user.svg';

export default class Heading extends Component {
  clicks = 0;
  timer = 0;
  static contextType = UserAuthContext;

  constructor(props) {
    super(props);
    // Can't use React.useRef() in class component
    this.logoRef = React.createRef();
    this.signInWithGoogle = () => { };
    this.signOut = () => { }
    // we need to set bubbles as true as we are capturing using document in UserAuth component.
    this.signInEvent = new Event("signin", { bubbles: true });
    this.fireClickEvent = this.fireClickEvent.bind(this);
    this.setMenuActive = this.setMenuActive.bind(this);
  }

  /**
   * When user clicks on the narbhavi logo dispatch an event
   * The event is subscribed in UserAuth component to sign in.
   */
  fireClickEvent(evt) {
    if (!this.context.isAuthenticated) {
      this.clicks++;
      if (this.clicks === 3) {
        this.signInWithGoogle();
      }
      setTimeout(() => {
        this.clicks = 0;
      }, 5000);
    }
  }

  setMenuActive = (e, menuName, obj) => {
    e.preventDefault();
    console.log(obj)
    console.log(e);
  }

  componentDidMount() {
    this.logoRef.current.addEventListener('click', this.fireClickEvent);
  }

  componentWillUnmount() {
    this.logoRef.current.removeEventListener('click', this.fireClickEvent);
  }

  render() {
    this.signInWithGoogle = this.context.signInWithGoogle;
    this.signOut = this.context.signOut;
    const { isAuthenticated } = this.context;

    return (
      <>
        <SharedAlert></SharedAlert>
        <header className="p-0 bg-nbv">
            <NavLink to="/">
              <img src={logo} id="brandlogo" ref={this.logoRef} className='img-fluid logo-hvr-shrink' aria-label='Narbhavi Logo' />
            </NavLink>
          <nav className='navbar-expand-lg navbar-inverse navbar-static-top' data-bs-theme="dark" role="navigation">
              <button className="navbar-toggler navbar-dark" type="button" data-bs-toggle="offcanvas" data-bs-target="#navigation" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="offcanvas offcanvas-end" tabIndex="-1" id="navigation">
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="header__search-menu offcanvas-body">
                  <form className="site-search form-inline input-group-sm">
                    <input type="text" id="sitesearchinput" className="form-control" placeholder="Search" aria-label="Search" aria-describedby="addon-wrapping" />
                    <span id="sitesearch" onClick={this.searchClear}><i className="fa-solid fa-search"></i></span>
                  </form>
                  <ul className="navbar-nav gradient sf-menu flex-grow-1">
                    <li className="nav-item">
                      <NavLink className="nav-link active" to="/" href="#" onClick={(event) => { this.setMenuActive(event, 'home') }}>HOME</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/" href="#" onClick={(event) => { this.setMenuActive(event, 'practices') }}>OUR PRACTICES</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="products" href="#" onClick={(event) => { this.setMenuActive(event, 'products') }}>PRODUCTS</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="products" href="#" onClick={(event) => { this.setMenuActive(event, 'blogs') }}>BLOGS</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="products" href="#" onClick={(event) => { this.setMenuActive(event, 'gallery') }}>GALLERY</NavLink>
                    </li>
                    <li className={'nav-item ' + isAuthenticated ? '' : 'disabled'}>
                      {isAuthenticated ?
                        <>
                          <NavLink to="Profile" activeclassname="active" className="nav-link text-white dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <ProfileUser className="usericon mb-1" width={20} height={20} />
                          </NavLink>
                          <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-lg-end" data-bs-display="static">
                            <li><a className="dropdown-item" href="#">Manage User</a></li>
                            <li><a className="dropdown-item" href="#">Purchase</a></li>
                            <li><NavLink to="Revenue" className="dropdown-item" href="#">Revenue</NavLink></li>
                            <li><hr className="dropdown-divider-light" /></li>
                            <li><NavLink to="Plants" className="dropdown-item" href="#">Plants</NavLink></li>
                            <li><NavLink to="DataManagement" className="dropdown-item" href="#">Downloads</NavLink></li>
                            <li><NavLink to="DataStructure" className="dropdown-item" href="#">Data Structure</NavLink></li>
                            <li><NavLink to="Profile" className="dropdown-item" onClick={(event) => { this.setMenuActive(event, 'auth') }} href="#">Profile</NavLink></li>
                            <li><NavLink to="Logout" className="dropdown-item" onClick={(event) => { this.setMenuActive(event, 'auth') }} href="#">Logout</NavLink></li>
                          </ul>
                        </>
                        : ''}
                    </li>
                  </ul>
                </div>
              </div>
          </nav>
        </header>
        <div className="hline"></div>
      </>
    )
  }
}
