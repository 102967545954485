import React, { Component } from 'react';
import { ReactComponent as WhatsApp } from './whatsapp.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as YouTube } from './youtube.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import './footer.css'

function Footer() {
  return (
    <>
      <div className="border-line"></div>
      <footer>
        <div className='footer--caption'>
          <span className="mb-3">Copyright &copy; {new Date().getFullYear()} Narbhavi, All rights reserved.</span>
        </div>
        <div className='footer--section'>
          <div className='footer--terms'>
            <div>Privacy Statement</div>
            <div>Do Not Sell Personal Information</div>
            <div>Cookie Policy</div>
            <div>Cookies Settings</div>
          </div>
          <div className='footer--phone'>
            <div>
              <i className="fa-solid fa-square-phone fa-shake fa-2xl" style={{ color: '#fff' }}></i>
            </div>
            <div>
              Phone:<br />
              +91 94496 16700
            </div>
          </div>
          <div className='footer--address'>
            <div>
              <i className="fa-solid fa-location-dot fa-2xl fa-bounce" style={{ color: '#fff', paddingTop: '30px' }}></i>
            </div>
            <div>
              Address:<br />
              Kannivaadi Hills, Tettupatti,<br />
              Near Madurai-Palani NH309 and SH37 Intersection,<br />
              Dindigul District,
              Tamil Nadu 624705
            </div>
          </div>
          <div className='footer--email'>
            <div style={{ paddingTop: '8px' }}>
              <i className="fa-solid fa-square-envelope fa-2xl" style={{ color: '#fff' }}></i>
            </div>
            <div>
              Email:<br />
              contact@narbhavi.farm
            </div>
          </div>
        </div>
        {/** 
            <ul className="nav col-md justify-content-end list-unstyled d-flex">
              <li className="ms-3"><a className="text-muted" href="#"><WhatsApp className="ms-2" width={20} height={20} /></a></li>
              <li className="ms-3"><a className="text-muted" href="#"><Instagram className="ms-2" width={20} height={20} /></a></li>
              <li className="ms-3"><a className="text-muted" href="#"><YouTube className="ms-2" width={20} height={20} /></a></li>
              <li className="ms-3"><a className="text-muted" href="#"><Twitter className="ms-2" width={20} height={20} /></a></li>
              <li className="ms-3"><a className="text-muted" href="#"><Facebook className="ms-2" width={20} height={20} /></a></li>
            </ul>
            
        <div className='container'>
          <div className='row'>
            <span className="mb-3">Copyright &copy; {new Date().getFullYear()} Narbhavi, All rights reserved.</span>
          </div>
          <div className='row' style={{ paddingTop: '16px' }}>
            <div className='col-3'>
              <div>Privacy Statement</div>
              <div>Do Not Sell Personal Information</div>
              <div>Cookie Policy</div>
              <div>Cookies Settings</div>
            </div>
            <div className='col-3'>
              <div className='row'>
                <div className='col-auto' style={{ paddingTop: '8px' }}>
                  <i className="fa-solid fa-square-phone fa-shake fa-2xl" style={{ color: '#fff' }}></i>
                </div>
                <div className='col'>
                  Phone:<br />
                  +91 94496 16700</div>
              </div>
            </div>
            <div className='col-3'>
              <div className='row'>
                <div className='col-auto'>
                  <i className="fa-solid fa-location-dot fa-2xl fa-bounce" style={{ color: '#fff', paddingTop: '30px' }}></i>
                </div>
                <div className='col'>
                  Address:<br />
                  Kannivaadi Hills, Tettupatti,<br />
                  Near Madurai-Palani NH309 and SH37 Intersection,<br />
                  Dindigul District,
                  Tamil Nadu 624705</div>
              </div>
            </div>
            <div className='col-3'>
              <div className='row'>
                <div className='col-auto' style={{ paddingTop: '8px' }}>
                  <i className="fa-solid fa-square-envelope fa-2xl" style={{ color: '#fff' }}></i>
                </div>
                <div className='col'>
                  Email:<br />
                  contact@narbhavi.farm</div>
              </div>
            </div>
          </div></div>
          */}
      </footer >
    </>
  )
}

export default Footer;



