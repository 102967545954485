import React, { Component } from 'react';

function Products() {
  return (
    <div className='main'><h3>Products</h3>
      <p>
        We do sell products like honey and manure.
      </p>
    </div>
  )
}

export default Products;