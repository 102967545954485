import React, { Component, forwardRef } from 'react';
import { db } from '../../../config/config';
import DataService from '../../../services/data.service';
import { collection, orderBy, query, getDocs, Timestamp } from "firebase/firestore";
import DropdownCheckbox from '../../components/DropdownCheckbox';
import PlantsModal from './PlantsModal';
import { t } from 'i18next';
import MessagePrompt from '../../../utils/MessagePrompt';
import { AlertContext } from '../../../utils/AlertContext';
import { TIME_STAMP_LABELS } from '../../../services/plants.validation.service';
import moment from 'moment/moment';
import { dropDownList, dropDownNames } from '../../../services/dropdownService';
import { clone } from 'lodash';
import './plants.css' // Import the CSS file

export default class Plants extends Component {
    static contextType = AlertContext;
    PLANTS_COLLECTION_NAME = 'plants';

    constructor(props) {
        super(props);
        // Initialize the component state with the value from local storage
        this.state = JSON.parse(localStorage.getItem('plantComponentState')) || {
            isLoading: true,
            selectedItem: null,
            selectAll: false,
            modalAction: null,
            gridHeaders: [{ key: 'id', value: 'Id', isChecked: true },
            { key: 'name', value: 'Plant Name', isChecked: true },
            { key: 'biologicalName', value: 'Biological Name', isChecked: true },
            { key: 'otherName', value: 'Other Name', isChecked: true },
            { key: 'plantType', value: 'Type', isChecked: true },
            { key: 'classification', value: 'Classification', isChecked: true },
            { key: 'plantedDate', value: 'Planted On', isChecked: true },
            { key: 'plantStatus', value: 'Status', isChecked: false },
            { key: 'infectedDate', value: 'Infected', isChecked: false },
            { key: 'infectedReason', value: 'Infected Reason', isChecked: false },
            { key: 'plantDeadDate', value: 'Dead date', isChecked: false },
            { key: 'deadReason', value: 'Dead Reason', isChecked: false },
            { key: 'surveyNumber', value: 'Survey#', isChecked: true },
            { key: 'blockNumber', value: 'Block#', isChecked: true },
            { key: 'vendorName', value: 'Vendor', isChecked: false },
            { key: 'plantCost', value: 'Cost', isChecked: false },
            { key: 'latitude', value: 'Latitude', isChecked: false },
            { key: 'longitude', value: 'Longitude', isChecked: false },
            { key: 'waterRequirement', value: 'Water', isChecked: false },
            { key: 'waterFrequency', value: 'Water Frequency', isChecked: false },
            { key: 'pruningFrequency', value: 'Pruning Frequency', isChecked: false },
            { key: 'lastPruned', value: 'Last Pruned', isChecked: false },
            { key: 'pruneEffort', value: 'Prune Time', isChecked: false },
            { key: 'fertigationFrequency', value: 'Fertigation Frequency', isChecked: false },
            { key: 'lastFertilised', value: 'Last Fertilised', isChecked: false },
            { key: 'fertileEffort', value: 'Fertile Time', isChecked: false },
            { key: 'harvestFrequency', value: 'Harvest Frequency', isChecked: false },
            { key: 'lastHarvested', value: 'Last Harvest', isChecked: false },
            { key: 'harvestEffort', value: 'Harvest Time', isChecked: false },
            { key: 'note', value: 'Note', isChecked: false },
            { key: 'updatedOn', value: 'Updated On', isChecked: false },
            { key: 'toDelete', value: 'Delete Marked', isChecked: false }],
            gridData: [],
            currentPage: 1,
            rowsPerPage: 10,
            totalPages: 1,
            selectedRows: [],
            sortBy: null,
            sortAsc: true,
            filterText: '',
            "plants": {
                id: '',
                name: '',
                biologicalName: '',
                otherName: '',
                plantType: '',
                classification: '',
                plantedDate: null,
                plantStatus: "",
                infectedDate: null,
                infectedReason: '',
                plantDeadDate: null,
                deadReason: '',
                surveyNumber: '',
                blockNumber: '',
                vendorName: '',
                plantCost: 0,
                latitude: 0,
                longitude: 0,
                waterRequirement: 0,
                waterFrequency: '',
                pruningFrequency: '',
                lastPruned: null,
                pruneEffort: 0,
                fertigationFrequency: '',
                lastFertilised: null,
                fertileEffort: 0,
                harvestFrequency: '',
                lastHarvested: null,
                harvestEffort: 0,
                note: '',
                updatedOn: null,
                toDelete: false,
                selected: false
            }
        };
        this.modalRef = React.createRef();
        this.plantsModal = null;
        this.deleteModalId = 'deleteModal';
        this.deleteModal = false;
        this.dataDisplayed = {};
        this.setGridData = this.setGridData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildValueChange = this.handleChildValueChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleIndividualFormSubmit = this.handleIndividualFormSubmit.bind(this);
        this.handleCloneorCreate = this.handleCloneorCreate.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.searchClear = this.searchClear.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectRow = this.handleSelectRow.bind(this);
        this.handleDeleteSelectedRows = this.handleDeleteSelectedRows.bind(this);
        this.handleHeaderCheckboxChange = this.handleHeaderCheckboxChange.bind(this);
        this.handleMessageDeleteInit = this.handleMessageDeleteInit.bind(this);
        this.handleMessageDeleteOk = this.handleMessageDeleteOk.bind(this);
        this.handleMessageDeleteCancel = this.handleMessageDeleteCancel.bind(this);
    }

    handleCloneorCreate = (e) => {
        e.preventDefault(); // required
        const selectedData = this.state.gridData.filter((item) => item.selected);
        if (selectedData.length === 1) {
            this.setState({ modalAction: 'clone', selectedItem: selectedData[0] }, () => {
                this.plantsModal.show();
                this.modalRef.current.invokedFromParent();
            });
        } else if (selectedData.length === 0) {
            this.setState({ modalAction: 'create', selectedItem: this.state.plants }, () => {
                this.plantsModal.show();
                this.modalRef.current.invokedFromParent();
            });
        } else {
            this.context.addAlert(t('Plants.validation.selectAny'), "warning");
        }
    }

    saveStateToLocalStorage = () => {
        // Save the component state to local storage
        localStorage.setItem('plantComponentState', JSON.stringify(this.state));
    };

    searchClear = () => {
        this.setState({ filterText: '' });
    }

    resetRowSelections = () => {
        let newGridData = this.state.gridData;
        newGridData.forEach((item) => {
            item.selected = false;
        })

        this.setState({ selectAll: false });
        this.setState(prevState => ({
            ...prevState.gridData,
            "gridData": newGridData
        }));
    }

    handleRefresh = (event) => {
        this.setGridData("plants");
    }

    handleHeaderCheckboxChange = (event) => {
        event.preventDefault();
        this.setState({ selectAll: event.target.checked }, () => {
            let newGridData = this.state.gridData;
            this.dataDisplayed.forEach((item) => {
                const index = newGridData.findIndex(row => row.docId === item.docId);
                if (index !== -1) {
                    newGridData[index].selected = event.target.checked;
                }
            });
            this.setState(prevState => ({
                ...prevState.gridData,
                "gridData": newGridData
            }));
        });
    }

    handleSelectAll = () => {
        const { selectAll } = this.state;
        const newData = this.state.gridData.map((item) => ({ ...item, selected: !selectAll }));
        this.setState({ gridData: newData, selectAll: !selectAll });
    };

    handleSelectRow = (event, row) => {
        const updatedData = this.state.gridData.map((item) =>
            item.docId === row.docId ? { ...item, selected: event.target.checked } : item
        );
        const updatedSelectedItem = event.target.checked ? row : this.state.plants;
        this.setState({ gridData: updatedData, selectedItem: updatedSelectedItem });
    };

    handleDeleteSelectedRows = () => {
        const newGridData = this.state.gridData;
        const isAnyRecordSelected = newGridData.some((item) => {
            return item.selected;
        });
        if (isAnyRecordSelected && this.deleteModal) {
            this.deleteModal.show();
        } else {
            this.context.addAlert("Select any record to delete.", "warning");
        }
    };

    handleModal = () => {
        // Initialize the modal window once
        this.plantsModal = {};
        this.plantsModal = new window.bootstrap.Modal(document.getElementById('plantmodal'));
    }

    handleMessageDeleteOk = async () => {
        let selectedData = this.state.gridData.filter((item) => item.selected);
        let newData = this.state.gridData.filter((item) => !item.selected);
        await DataService.deleteSelectedRecords(selectedData, "plants");
        // Assuming the data will be successfully deleted. 
        this.setState({ gridData: newData, selectAll: false })
        this.deleteModal.hide();
    }

    handleMessageDeleteCancel = () => {
        this.deleteModal.hide();
    }

    handleMessageDeleteInit = (id) => {
        this.deleteModal = new window.bootstrap.Modal(document.getElementById(id));
    }

    // Handle individual data changes
    handleChange = (event) => {
        // event.target.name - field name
        // event.target.value = field value
        console.log("Handle Change");
    }

    handleFilterChange(event) {
        this.setState({ filterText: event.target.value.trim() });
    }

    handleRowClick(e, item) {
        e.preventDefault(); // required
        this.setState({ selectedItem: item, modalAction: 'edit' }, () => {
            this.modalRef.current.invokedFromParent();
            this.plantsModal.show();
        });
    }

    handleModalClose(e) {
        e.preventDefault(); // required
        this.plantsModal.hide();
        this.setState({ selectedItem: this.state.plants });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /**
     * Convert Time Stamp from Firebase Timestamp to React
     * @param {string} value 
     * @param {string} label 
     * @returns 
     */
    convertToReactTimestamp = (key, value, ignoreUpdatedOn) => {
        if (TIME_STAMP_LABELS[key]) {
            if (key == 'updatedOn' && ignoreUpdatedOn) {
                return moment(new Date()).format('DD-MMM-YY');
            } else {
                return moment(value.toDate()).format('DD-MMM-YY');
            }
        }
        return value;
    }

    addToGrid = (modifiedFields) => {
        let newRecord = {}
        const newGridData = this.state.gridData;
        Object.keys(modifiedFields).map((key) => {
            newRecord[key] = this.convertToReactTimestamp(key, modifiedFields[key], true)
        })
        newGridData.push(newRecord);
        this.setState(prevState => ({
            ...prevState,
            "gridData": newGridData
        }));
    }

    updateToGrid = (modifiedFields) => {
        const newGridData = this.state.gridData;
        const { selectedItem } = this.state;

        Object.keys(modifiedFields).map((key) => {
            selectedItem[key] = this.convertToReactTimestamp(key, modifiedFields[key], true)
        })

        const index = newGridData.findIndex(row => row.docId === selectedItem.docId);
        if (index !== -1) {
            newGridData[index] = selectedItem;
        }
        this.setState(prevState => ({
            ...prevState,
            "gridData": newGridData
        }));
    }

    handleIndividualFormSubmit = async (e, modifiedFields, action, duplicateCount) => {
        e.preventDefault();
        if (action === 'create') {
            const isAdded = await DataService.addNewRecord("plants", modifiedFields);
            if (isAdded) {
                this.context.addAlert(t('Plants.validation.success'), "success");
                this.addToGrid(modifiedFields);
                this.plantsModal.hide();
            } else {
                this.context.addAlert(t('Plants.validation.failure'), "danger");
            }
        } else if (action === 'edit') {
            const selectedItem = this.state.selectedItem;
            const isUpdated = await DataService.updateFieldsInCollection(this.PLANTS_COLLECTION_NAME,
                modifiedFields, selectedItem.docId);
            if (isUpdated) {
                this.context.addAlert(t('Plants.validation.updated'), "success");
                this.updateToGrid(modifiedFields);
            }
        } else if (action === 'clone') {
            this.plantsModal.hide();
            const isUpdated = await DataService.duplicateRecords(this.PLANTS_COLLECTION_NAME,
                modifiedFields, duplicateCount);
            if(isUpdated) {
                this.context.addAlert(t('Plants.validation.success'), "success");
                this.setGridData("plants");
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // only re-render if the props or state have changed
        return true
    }

    addData = (idx) => {
        let data = {
            id: 'NBT' + idx,
            name: 'Coconut',
            biologicalName: 'Cocos nucifera',
            otherName: 'Tennai',
            plantType: 'Tree',
            classification: 'CT',
            plantedDate: 0,
            plantStatus: "alive",
            infectedDate: 0,
            infectedReason: '',
            plantDeadDate: 0,
            deadReason: '',
            surveyNumber: 'SA',
            blockNumber: 'B1',
            vendorName: 'Some name',
            plantCost: 150,
            latitude: 10.394721,
            longitude: 77.807318,
            waterRequirement: 20,
            waterFrequency: 'weekly',
            pruningFrequency: 'sixmonths',
            lastPruned: 0,
            pruneEffort: 15,
            fertigationFrequency: 'monthly',
            lastFertilised: 0,
            fertileEffort: 120,
            harvestFrequency: '60days',
            lastHarvested: 0,
            harvestEffort: 60,
            note: 'This plant needs additional water.',
            updatedOn: '',
            toDelete: false
        };
        DataService.addNewRecord("plants", data);
    };

    handleSort(event, column) {
        event.preventDefault();

        const { gridData, sortBy, sortAsc } = this.state;

        let sortedData = [...gridData];

        if (column === sortBy) {
            sortedData.reverse();
            this.setState({ gridData: sortedData, sortAsc: !sortAsc });
        } else {
            sortedData.sort((a, b) => {
                if (a[column] < b[column]) return sortAsc ? -1 : 1;
                if (a[column] > b[column]) return sortAsc ? 1 : -1;
                return 0;
            });

            this.setState({ gridData: sortedData, sortBy: column, sortAsc: true });
        }
    }

    getGridData = (async (collection_name) => {
        let gridData = [];
        try {
            const queryRef = await query(collection(db, collection_name), orderBy('updatedOn', 'desc'));
            const querySnapshot = await getDocs(queryRef);

            gridData = querySnapshot.docs.map((doc) => ({
                docId: doc.id,
                selected: false,
                ...doc.data()
            }));
            return gridData;
        } catch (error) {
            console.log(error.errMsg);
        }
    })

    /**
     * Get all the data from the collection and update
     * the grid parameters
     * 
     * @param {string} collection_name Collection name
     */
    setGridData = async (collection_name) => {
        let gridData = await this.getGridData(collection_name);
        try {
            gridData.forEach((item) => {
                for (const key in item) {
                    if (key === 'updatedOn' || key === 'plantDeadDate' || key === 'lastFertilised' ||
                        key === 'plantedDate' || key === 'lastPruned' || key === 'infectedDate' ||
                        key === 'infectedDate' || key === 'lastHarvested' || key === 'updatedOn') {
                        if (item[key] && typeof item[key] === 'object') {
                            item[key] = moment(item[key].toDate()).format('DD-MMM-YY')
                        } else {
                            item[key] = null;
                        }
                    }
                }
            })
            this.setState({ gridData: gridData }, () => {
                this.setState({ totalPages: Math.ceil(gridData.length / this.state.rowsPerPage) });
                this.setState({ isLoading: false });
            });
        } catch (error) {
            this.setState({ isLoading: false });
            console.log(error);
        }
        return gridData;
    }

    componentDidMount() {
        for (let index = 1; index < 51; index++) {
            // this.addData(index);
        }
        // deleteAllRecords("plants");
        // DataService.getUniqueId("SAB1CC", "plants");
        this.setGridData("plants");
        const savedState = localStorage.getItem("plantComponentState");
        if (savedState) {
            this.setState(JSON.parse(savedState));
        }
    }

    componentDidUpdate() {
        // Save state to localStorage
        localStorage.setItem('plantComponentState', JSON.stringify(this.state));
    }

    handlePageChange = async (pageNumber) => {
        await this.setState({ currentPage: pageNumber });
        this.resetRowSelections();
    };

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleChildValueChange(gridHeaders) {
        this.setState({ gridHeaders });
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    /**
     * Get the labels
     * @param {string} value 
     * @returns 
     */
    getDropDownListLabel = (label, value) => {
        if (dropDownNames[value]) {
            try {
                let item = dropDownList[value].find((option) => option.value === label);
                return item.label;
            } catch (error) {
                console.log(error);
            }
        }
        return label;
    }    

    render() {
        const { gridData, currentPage, rowsPerPage, gridHeaders, modalAction,
            selectedItem, sortBy, sortAsc, filterText, selectAll, plants } = this.state;
        let pageNumbers = 1;

        const filteredData = gridData.filter((row) =>
            Object.values(row)
                .join('')
                .toLowerCase()
                .includes(filterText.toLowerCase().trim())
        );

        // Calculate the start and end indexes of the items to display on the current page

        const startIndex = (currentPage - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;

        // Slice the array of plants to display only the items on the current page
        this.dataDisplayed = filteredData.slice(startIndex, endIndex);
        pageNumbers = Math.ceil(filteredData.length / this.state.rowsPerPage)

        return (
            <div className='main'>
                <div className='container-md'>
                    <div className="row mt-3 mb-3">
                        <div className="form-group col-md-6">
                            <h4 className='ml-0 mb-0 mt-4 mb-2'>{t('Plants.heading')}</h4>
                        </div>
                        <div className="form-group col-md-6">
                            &nbsp;
                        </div>
                    </div>
                    {
                        this.state.isLoading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="row mt-3 mb-3">
                                    <div className="form-group col-md-3">
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <i className="fas fa-filter"></i>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="searchFilter"
                                                value={filterText}
                                                onChange={this.handleFilterChange}
                                            />
                                            <div id="searchclear" onClick={this.searchClear}><i className="fa-solid fa-xmark"></i></div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-3">
                                        &nbsp;
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div className='float-end'>
                                            <button type="button" className="btn btn-primary me-2" onClick={this.handleCloneorCreate}>
                                                <span><i className="fa-solid fa-clone"></i></span>
                                            </button>
                                            <button type="button" className="btn btn-primary me-2" onClick={this.handleRefresh}>
                                                <span><i className="fa-solid fa-arrows-rotate"></i></span>
                                            </button>
                                            <button type="button" className="btn btn-primary me-2" onClick={this.handleDeleteSelectedRows}>
                                                <span><i className="fa-solid fa-trash-can"></i></span>
                                            </button>
                                            <button type="button" className="btn btn-primary me-2">
                                                <span><i className="fa-solid fa-cloud-arrow-down"></i></span>
                                            </button>
                                            <DropdownCheckbox onChildValueChange={this.handleChildValueChange} menuwidth="200px" headers={gridHeaders}></DropdownCheckbox>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <table id="plantgrid" className="table table-responsive-lg bordered table-hover">
                                        <thead className='table-secondary'>
                                            <tr>
                                                <th className='user-select-none'>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={selectAll}
                                                        onChange={this.handleHeaderCheckboxChange}
                                                    />
                                                </th>
                                                {gridHeaders.map(header => {
                                                    return header.isChecked &&
                                                        <th className='user-select-none' key={header.key} onClick={(e) => this.handleSort(e, header.key)}>
                                                            <span>
                                                                {header.value} {sortBy === header.key ? (sortAsc ? '▲' : '▼') : ''}
                                                            </span>
                                                        </th>;
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.dataDisplayed.map((row, index) => (
                                                <tr key={index} className="user-select-none" onDoubleClick={(evt) => this.handleRowClick(evt, row)}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={row['selected']}
                                                            onChange={(event) => this.handleSelectRow(event, row)}
                                                        />
                                                    </td>
                                                    {gridHeaders.map(header => {

                                                    // return header.isChecked && <td key={header.key}>{row[header.key]}</td>;

                                                        return header.isChecked && <td key={header.key}>
                                                            {this.getDropDownListLabel(row[header.key], header.key)}
                                                        </td>;
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <nav>
                                        <ul className="pagination justify-content-center">
                                            {[...Array(pageNumbers).keys()].map((pageNumber) => (
                                                <li key={pageNumber} className={`page-item ${pageNumber + 1 === currentPage ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => this.handlePageChange(pageNumber + 1)}>
                                                        {pageNumber + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                                <PlantsModal
                                    ref={this.modalRef}
                                    data={selectedItem}
                                    action={modalAction}
                                    emptyData={plants}
                                    handleModal={this.handleModal}
                                    handleChange={this.handleChange}
                                    handleSubmit={this.handleIndividualFormSubmit}
                                    closeModal={this.handleModalClose}>
                                </PlantsModal>
                                <MessagePrompt
                                    title={t('Plants.prompts.delete.title')}
                                    modalId='deleteModal'
                                    iconClass='fa-3x fa-solid fa-triangle-exclamation icon-yellow'
                                    message={t('Plants.prompts.delete.message')}
                                    textCancel={t('Plants.prompts.delete.cancel')}
                                    textOk={t('Plants.prompts.delete.ok')}
                                    initModal={this.handleMessageDeleteInit}
                                    handleButtonCancel={this.handleMessageDeleteCancel}
                                    handleButtonOk={this.handleMessageDeleteOk}>
                                </MessagePrompt>
                            </>
                        )}
                </div>
            </div>
        );
    }
}
