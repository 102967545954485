import React, { Component } from 'react'
import UserAuthContext from '../../../utils/UserAuthProvider';

export default class Profile extends Component {
  static contextType = UserAuthContext;

/**
 * Convert epoch date to dd mmm yyyy
 * 
 * @param {int} epoch time in milliseconds
 * @returns formatted date
 */
  getFormattedDate(epoch) {
    let dt = new Date(epoch);
    let dob = "";
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    dob = dt.toLocaleString("en-GB", options);
    return dob;
  }

  constructor(props) {
    super(props);
  }

  render() {
    let userInfo = {
      displayName: '', dob: 0, email: '', empId: '',
      firstName: '', gender: '', lastName: '', mobile: '',
      roleName: ''
    };

    let dob=0;

    if (this.context) {
      userInfo = this.context.userInfo;
      let ms = userInfo.dob.seconds * 1000; // convert to milliseconds
      dob = this.getFormattedDate(ms);
    }

    return (
      <div className='main container-md'>
        <h2 className='mb-0 mt-3 mb-3'>My Profile</h2>
        <form className="g-3 row">
          <div className="col-md-12">
            <p className="mb-0">Welcome {userInfo.displayName},</p>
            <p className="mt-1">The below data cannot be edited, contact your admin for any update.</p>
          </div>
          <div className="col-md-6">
            <label className="fw-bold">First Name</label>
            <input type="text" readonly className="form-control-plaintext" defaultValue={userInfo.firstName} />
          </div>
          <div className="col-md-6">
            <label className="fw-bold">Last Name</label>
            <input type="text" readonly className="form-control-plaintext" defaultValue={userInfo.lastName} />
          </div>
          <div className="col-md-6">
            <label className="fw-bold">Employee Id</label>
            <input type="text" readonly className="form-control-plaintext mt-0" defaultValue={userInfo.empId} />
          </div>
          <div className="col-md-6">
            <label className="fw-bold">eMail</label>
            <input type="text" readonly className="form-control-plaintext mt-0" defaultValue={userInfo.email} />
          </div>
          <div className="col-md-6">
            <label className="fw-bold">Gender</label>
            <input type="text" readonly className="form-control-plaintext" defaultValue={userInfo.gender} />
          </div>
          <div className="col-md-6">
            <label className="fw-bold">DOB</label>
            <input type="text" readonly className="form-control-plaintext" defaultValue={dob} />
          </div>
          <div className="col-md-6">
            <label className="fw-bold">Mobile</label>
            <input type="text" readonly className="form-control-plaintext" defaultValue={userInfo.mobile} />
          </div>
          <div className="col-md-6">
            <label className="fw-bold">Role Name</label>
            <input type="text" readonly className="form-control-plaintext" defaultValue={userInfo.roleName} />
          </div>          
        </form>
      </div>
    )
  }
}