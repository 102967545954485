import { db } from "../config/config";
import { collection, doc, writeBatch, getDocs, query, where, orderBy, limit, addDoc, updateDoc, deleteDoc, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { AlertContext } from "../utils/AlertContext";
import { t } from 'i18next';

class DataService {

  static contextType = AlertContext;
  // Add all the collection in the firestore here
  // There are no web libraries available at this time
  // to get the collection list
  COLLECTION_LIST = [
    { label: 'User Accounts', cname: 'user_account' },
    { label: 'Plants', cname: 'plants' }]

  COLLECTION_LIST_DEV = [
    { label: 'User Accounts', cname: 'user_account_demo' },
    { label: 'Plants', cname: 'plants_demo' }]

  /**
   * This deletes all the records in the collection.
   * Should be used carefully. Only for development purpose.
   * @param {string} collection_name Name of the collection 
   */
  deleteAllRecords = (async (collection_name) => {
    const querySnapshot = await getDocs(collection(db, collection_name));

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    })
  });

  /**
   * Delete all the marked records which has been marked for deleted more than the passed days.
   * @param {string} collection_name Name of the collection
   * @param {number} days Delete the records which has been updated more than the passed days.
   */
  deleteSelectedRecords = (async (records, collection_name) => {
    let recSize = records.length;
    let delSize = 0;
    try {
      records.forEach(async (record) => {
        await deleteDoc(doc(db, collection_name, record.docId))
          .then(() => {
            delSize++;
          })
          .catch((error) => {
            console.error('Error deleting data in Firestore: ', error);
          });
      })
      if (delSize === recSize) {
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  });

  addToList = async (listName, newData) => {
    console.log("lists" + " " + listName + JSON.stringify(newData));
    const plantTypeDoc = doc(collection(db, "lists"), listName);
    await updateDoc(plantTypeDoc, {
      options: arrayUnion(...newData.options)
    });
  }

  getList = async (listName) => {
    // Define the collection and document
    const listsCollection = collection(db, "lists");
    const plantTypeDoc = doc(listsCollection, listName);

    // Get the document data and log it to the console
    return getDoc(plantTypeDoc)
      .then((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const options = data.options.map((item) => ({
            label: item.label,
            value: item.value,
          }));
          return options;
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        return {};
      });
  }

  /**
   * Mark the records to be deleted. These records will be purged in a batch
   * periodically.
   * @param {string} collection_name Name of the collection
   * @param {object} idArray Array of record id's  
   */
  markRecordsForDeletion = (async (collection_name, idArray) => {
    const queryRef = await query(collection(db, collection_name), where('id', 'in', idArray));
    const querySnapshot = await getDocs(queryRef);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        try {
          // Update the field in the document
          await updateDoc(doc.ref, { toDelete: true });
          console.log("Field with doc.id " + doc.id + " is successfully marked for deletion!");
        } catch (error) {
          console.error("Error updating field:", error);
        }
      })
    }
  });

  // Function to update the document
  updateDoc = async (transaction, docRef, newData) => {
    const docSnap = await transaction.get(docRef);
    transaction.set(docRef, { ...docSnap.data(), ...newData });
  };

  duplicateRecords = async (collection_name, data, duplicateCount) => {
    const collectionRef = collection(db, collection_name);
    const idRegex = /(\d+)$/; // Regular expression to extract last digits
    const currentId = data.id; // Your current ID
    const idPrefix = currentId.substring(0, currentId.length - 3);
    const lastId = await this.getUniqueId(idPrefix, collection_name);
    const lastDigits = parseInt(lastId.match(idRegex)[1], 10); // Extract and convert to number

    // Keeping it 0, so that the existing id is also added
    // Generate new IDs and add new documents in a batch write
    const batch = writeBatch(db);
    for (let i = 0; i <= duplicateCount; i++) {
      const newLastDigits = lastDigits + i;
      const newId = `${idPrefix}${newLastDigits.toString().padStart(3, "0")}`;
      const docRef = doc(collectionRef, newId);
      batch.set(docRef, { ...data, id: newId });
    }
    return batch.commit()
      .then(() => {
        return true;
      })
      .catch((error) => {
        this.context.addAlert(`${error.name}: ${error.message}`, "danger");
        return false;
      });
  }

  /**
   * Add a record to the passed collection name.
   * @param {string} collection_name 
   * @param {object} data 
   */
  addNewRecord = (async (collection_name, data) => {
    return addDoc(collection(db, collection_name), data)
      .then(() => {
        return true;
      })
      .catch((error) => {
        this.context.addAlert(`${error.name}: ${error.message}`, "danger");
        return false;
      });
  })

  /**
   * Returns all the data from the collection
   * @param {string} collection_name The collection name 
   * @returns {object} collection data
   */
  getAllRecordsFromCollection = (async (collection_name) => {
    try {
      let data = [];
      if (collection_name) {
        const querySnapshot = await getDocs(collection(db, collection_name));

        if (!querySnapshot.empty) {
          querySnapshot.forEach(async (doc) => {
            let record = {}
            record = doc.data()
            record["docId"] = doc.id;
            data.push(record);
          })
        }
        return data;
      }
    } catch (error) {
      this.context.addAlert(`${error.name}: ${error.message}`, "danger");
    }
  });

  /**
   * Returns all the data from the collection
   * @param {string} collection_name The collection name 
   * @returns {object} collection data
   */
  getCsvDataFromCollection = (async (collection_name) => {
    try {
      let data = [], csvData;
      if (collection_name) {
        console.log("Collection name: " + collection_name);
        const querySnapshot = await getDocs(collection(db, collection_name));

        try {
          data.push(querySnapshot.docs[0].data());
        } catch (error) {
          console.log(error)
        }

        csvData = this.jsonToCsv(data);
        // console.log(JSON.stringify(querySnapshot.docs[0].data()));
        return csvData;
      }
    } catch (error) {
      this.context.addAlert(`${error.name}: ${error.message}`, "danger");
    }
  });

  updateFieldsInCollection = async (collection_name, modifiedFields, docId) => {
    const docRef = doc(db, collection_name, docId);
    return updateDoc(docRef, modifiedFields)
      .then(() => {
        return true;
      })
      .catch((error) => {
        this.context.addAlert(`${error.name}: ${error.message}`, "danger");
        return false;
      });
  };

  /**
   * This method retrieves only the IDs from the collection_name
   * where the first 6 letters match a given prefix and the 
   * last 3 characters are numbers, sorted in descending order and
   * returns the last id.
   * 
   * @param {string} prefix Prefix of the unique id
   * @param {string} collection_name Name of the collection
   */
  getUniqueId = async (prefix, collection_name) => {
    let newPlantId = prefix + "001";
    const plantsRef = await collection(db, collection_name);
    const q = await query(
      plantsRef,
      where("id", ">=", prefix + "000"),
      where("id", "<=", prefix + "999"),
      orderBy("id", "desc"),
      limit(1)
    );

    let querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const lastPlantId = querySnapshot.docs[0].data().id;
      // Extract the number from the last ID and add 1 to it
      const len = lastPlantId.length;
      const lastNumber = parseInt(lastPlantId.substring(len, len-3), 10);
      const newNumber = lastNumber + 1;

      // Create the new ID by combining the prefix and the new number with leading zeros
      newPlantId = `${prefix}${newNumber.toString().padStart(3, '0')}`;
    } else {
      console.log("No matching documents found, creating a new id for prefix: ", newPlantId);
    }
    return newPlantId;
  }


  /**
   * Converts JSON data in an array to CSV data
   * @param {object} json JSON data
   * @returns {string} csv CSV data
   */
  jsonToCsv = (json) => {
    console.log(json)
    const items = json;
    const replacer = (key, value) => (value === null ? '' :
      (typeof value === 'object' ? JSON.stringify(value).replace(/,/g, '|').replace(/"|\{|\}/g, '') : value)); // handle null values
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','), // create CSV header row
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(','),
      ), // create CSV data rows
    ].join('\r\n');
    return csv;
  };

  /**
   * Download the CSV data received in the given filename.
   * @param {object} csv 
   * @param {string} filename 
   */
  downloadCsv = (collection_name) => {
    let csvData = this.getCsvDataFromCollection(collection_name);
    csvData.then(data => {
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', collection_name + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }).catch(error => {
      this.context.addAlert(`${error.name}: ${error.message}`, "danger");
    });
  };
}

export default DataService = new DataService();