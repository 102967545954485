import React, { Component } from 'react'

function Team() {
    return (
      <div className='main'><h3>Team</h3>
      <p>
        The below is our happy workforce.
      </p>
      </div>
    )
  }

export default Team;