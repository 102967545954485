import React, { Component } from 'react';
import { AlertContext } from './AlertContext';
import './SharedAlert.css';

export default class SharedAlert extends Component {
    static contextType = AlertContext;
  
    handleClose = (id) => {
      this.context.removeAlert(id);
    };
  
    render() {
      const { alertsQueue } = this.context;
  
      return (
        <div className="show-top">
          {alertsQueue.map((alert, index) => (
            <div
              key={index}
              className={`alert alert-${alert.type} alert-dismissible fade show`}
              role="alert"
            >
              {alert.message}
              <button
                type="button"
                className={`btn btn-outline-${alert.type} btn-sm float-end`}
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => this.handleClose(alert.id)}
              ><i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          ))}
        </div>
      );
    }
  }
  