import { data } from "jquery";
import React, { Component } from "react";
import dataService from "../../../services/data.service";
import CollectionDropDown from "../../../utils/CollectionDropDown";
import { debounce } from "lodash";

export default class DataStructure extends Component {
    constructor(props) {
        super(props);
        this.dsModal = {};
        this.state = {
            showModal: true,
            fieldType: "",
            fieldIndex: 0,
            messages: "",
            errors: "",
            selectedCollection: "",
            data: [],
            template: {
                "fieldKey": {
                    "docId": "",
                    "fieldId": "",
                    "label": "",
                    "colHeading": "",
                    "placeHolder": "",
                    "defaultValue": "",
                    "messages": {
                        "msg1": "",
                        "msg2": ""
                    },
                    "errors": {
                        "err1": "",
                        "err2": ""
                    },
                    "dataType": "string"
                }
            },
            collectionList: dataService.COLLECTION_LIST_DEV
        };
        this.data = [];
        this.handleChange = debounce(this.handleChange.bind(this), 500);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalSave = this.handleModalSave.bind(this);
        this.handleMessagesOpen = this.handleMessagesOpen.bind(this);
        this.handleErrorsOpen = this.handleErrorsOpen.bind(this);
        this.handleAddRecord = this.handleAddRecord.bind(this);
    }

    handleSaveRecord = (event) => {
        const { selectedCollection, data } = this.state;
        const objToSave = {[selectedCollection]:{}, id: ""};

        data.forEach(element => {
            let fieldId = element.fieldId;
            let rowObj = {
                label:element.label, 
                colHeading:element.colHeading, 
                placeHolder:element.placeHolder, 
                defaultValue:element.defaultValue, 
                messages:element.messages, 
                errors: element.errors};
            console.log(rowObj);
            objToSave[selectedCollection][fieldId]=rowObj;
            console.log(JSON.stringify(objToSave));
        });
        // console.log(this.state[selectedCollection]);
        dataService.addNewRecord(selectedCollection, objToSave);

        // console.log(this.state[selectedCollection]);

    }

    handleAddRecord = (event) => {
        const { data } = this.state;
        let dataStructure = {
            docId: "", fieldId: "", label: "", colHeading: "",
            placeHolder: "", defaultValue: "", messages: {}, errors: {}
        }
        data.push(dataStructure);
        this.setState({ data })
        console.log(this.state.data);
        // const { selectedCollection } = this.state;

        /*
        const newObj = {
            ...this.state.template, // copy all properties from the original object
            "id": this.state.template.fieldKey, // add new property with the renamed value
        };
        delete newObj.fieldKey; // remove the old property
        console.log(this.state[selectedCollection]);

        if (this.state[selectedCollection].length > 0) {
            let oldObj = { ...this.state[selectedCollection] };
            if (!oldObj.hasOwnProperty('id')) {
                fieldsObj = { ...oldObj, newObj };
            }
        } else {
            fieldsObj = { ...newObj };
        }

        console.log(fieldsObj);

        this.setState(prevState => ({
            ...prevState.data,
            [selectedCollection]: fieldsObj
        }))
        setTimeout(() => {
            console.log(this.state);
        }, 1000);
        */
    }

    handleChange = (event, index) => {
        const { name, value } = event.target;
        const { data } = this.state;
        data[index][name] = value;
        // this.data[fieldIndex] = this.state[selectedCollection];
        // 
        console.log(data);
        this.setState(prevState => ({
            ...prevState.data,
            data: data
        }))

        console.log(this.state.data);
        // this.setState({ handleChange: selectedCollection, data });
    };

    handleModalClose = (event) => {
        this.setState({ showModal: false });
    };

    handleModalSave = () => {
        const { data } = this.state;

        data.array.forEach(item => {
            console.log(item);
            console.log(item.id);
        });
        console.log(this.state.selectedCollection);
        // this.state[this.state.selectedCollection];
        console.log(this.state[this.state.selectedCollection]);
    };

    handleMessagesOpen = (event, index) => {
        this.setState({ showModal: true, fieldType: "messages", fieldIndex: index }, () => {
            this.dsModal.show()
        });
        event.preventDefault(); // required
    };

    handleErrorsOpen = (event, index) => {
        this.setState({ showModal: true, fieldType: "errors", fieldIndex: index }, () => {
            this.dsModal.show()
        });
        event.preventDefault(); // required
    };

    selectedCollection = async (event) => {
        try {
            const collectionName = event.target.value;
            const records = await dataService.getAllRecordsFromCollection(collectionName);
            this.setState({ selectedCollection: collectionName });
            
            this.setState(prevState => ({
                ...prevState.data,
                data: []
            }))

            if (records.length > 0) {
                if (records.length > 1 || Object.keys(records[0]).length > 1) {
                    this.setState({ selectedCollection: collectionName, records });
                } else {
                    console.log("Empty");
                }
            }
        } catch (err) {
            console.log(err);
        }
    };


    componentDidMount() {
        let modalNode = document.getElementById('dataStructureModal');
        if (modalNode) {
            this.dsModal = new window.bootstrap.Modal(document.getElementById('dataStructureModal'));
        }

        // Set the array in state
        dataService.COLLECTION_LIST_DEV.forEach((item) => {
            this.setState({ [item.cname]: [] });
        })
    }

    render() {
        const { data, showModal, fieldType, messages, errors, selectedCollection } = this.state;

        return (
            <div className='main container-md'>
                <div><h4 className='ml-0 mb-0 mt-4 mb-2'>Data Structure</h4></div>
                <div className="row form-group mt-3 mb-3">
                    <div className="col-md-3">
                        <CollectionDropDown selectedCollection={this.selectedCollection}></CollectionDropDown>
                    </div>
                    <div className="col-md-6">&nbsp;</div>
                    <div className='col-md-3'>
                        <div className="float-end">
                            <button className="btn btn-primary" onClick={(event) => this.handleAddRecord(event)} disabled={(selectedCollection === "" ? true : false)}>
                                <i className="fa-solid fa-folder-tree"></i>&nbsp;Add Field
                            </button>
                            <button className="btn btn-primary ms-2" onClick={(event) => this.handleSaveRecord(event)} disabled={(selectedCollection === "" ? true : false)}>
                                <i className="fa-solid fa-cloud-arrow-up"></i>&nbsp;Save
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <table className="table table-responsive-lg bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Field Id</th>
                                    <th>Field Label</th>
                                    <th>Column Heading</th>
                                    <th>Place Holder</th>
                                    <th>Default Value</th>
                                    <th>Data Type</th>
                                    <th>Text</th>
                                    <th>Errors</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="fieldId"
                                                        placeholder="Enter Field Id"
                                                        defaultValue=""
                                                        onChange={(event) => this.handleChange(event, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="label"
                                                        placeholder="Enter Field Label"
                                                        defaultValue="id"
                                                        onChange={(event) => this.handleChange(event, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="colHeading"
                                                        placeholder="Enter Column Header"
                                                        defaultValue="Id"
                                                        onChange={(event) => this.handleChange(event, index)}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="placeHolder"
                                                        placeholder="Enter Placeholder Text"
                                                        defaultValue="Enter Id"
                                                        onChange={(event) => this.handleChange(event, index)}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="defaultValue"
                                                        placeholder="Enter Default Value"
                                                        defaultValue=" "
                                                        onChange={(event) => this.handleChange(event, index)}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <select
                                                        className="form-select"
                                                        name="dataType"
                                                        placeholder="Select Data Type"
                                                        defaultValue="string"
                                                        onChange={(event) => this.handleChange(event, index)}
                                                    >
                                                        <option value="string">String</option>
                                                        <option value="number">Number</option>
                                                        <option value="boolean">Boolean</option>
                                                        <option value="boolean">Geopoint</option>
                                                        <option value="boolean">Date</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <button className="btn btn-secondary" onClick={(event) => this.handleMessagesOpen(event, index)}>
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button className="btn btn-secondary" aria-label="Edit Messages" onClick={(event) => this.handleErrorsOpen(event, index)}>
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button className="btn btn-secondary" aria-label="Edit Error Messages" onClick={(event) => this.handleErrorsOpen(event, index)}>
                                                        <i className="fa-solid fa-trash-can"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {showModal && (
                    <div className="modal" id="dataStructureModal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{fieldType === "messages" ? "Edit Messages" : "Edit Errors"}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleModalClose} />
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label htmlFor="messages">Messages:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="messages"
                                            value={messages}
                                            onChange={(event) => this.setState({ messages: event.target.value })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="errors">Errors:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="errors"
                                            value={errors}
                                            onChange={(event) => this.setState({ errors: event.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleModalClose}>Close</button>
                                    <button type="button" className="btn btn-primary" aria-label="Save" onClick={this.handleModalSave}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

