import React, { Component } from 'react';
import UserAuthContext from '../../utils/UserAuthProvider';

export default class Logout extends Component {
  static contextType = UserAuthContext;
    render() {
        const {signOut, isAuthenticated} = this.context;
        this.context.signOut();
        return (
            (isAuthenticated?"<div className='main'>You have been logged out.</div>":"<div className='main'>Logging out...</div>")
        )
    }
}
