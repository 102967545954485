import * as Yup from "yup";

export const TIME_STAMP_LABELS = {
    updatedOn: true,
    plantDeadDate: true,
    lastFertilised: true,
    plantedDate: true,
    lastPruned: true,
    infectedDate: true,
    lastHarvested: true,
};

export const validationPlantSchema = (t, getFormattedDate) =>
    Yup.object().shape({
        name: Yup.string()
            .required(t('PlantsModal.validation.name.required'))
            .min(4, t('PlantsModal.validation.name.min', { min: '4' }))
            .max(32, t('PlantsModal.validation.name.max', { max: '32' }))
            .matches(/^[\w\s-\.]+$/, t('PlantsModal.validation.name.matches')),
        biologicalName: Yup.string().when('biologicalName', {
            is: '',
            then: () => Yup.string().notRequired(),
            otherwise: () => (Yup.string()
                .min(3, t('PlantsModal.validation.biologicalName.min', { min: '3' }))
                .max(32, t('PlantsModal.validation.biologicalName.max', { max: '32' }))
                .matches(/^[\w\s-\.]+$/, t('PlantsModal.validation.biologicalName.matches'))
                .required()),
        }),
        otherName: Yup.string().when('otherName', {
            is: '',
            then: () => Yup.string().notRequired(),
            otherwise: () => (Yup.string()
                .min(3, t('PlantsModal.validation.otherName.min', { min: '3' }))
                .max(32, t('PlantsModal.validation.otherName.max', { max: '32' }))
                .matches(/^[\w\s-\.]+$/, t('PlantsModal.validation.otherName.matches'))
                .required()),
        }),
        plantType: Yup.string()
            .required(t('PlantsModal.validation.plantType.required')),
        classification: Yup.string()
            .required(t('PlantsModal.validation.classification.required')),
        plantedDate: Yup.date()
            .required('Need date')
            .typeError(t('PlantsModal.validation.plantedDate.typeError'))
            .min(new Date(2020, 0, 1), t('PlantsModal.validation.plantedDate.required'))
            .max(new Date(), t('PlantsModal.validation.plantedDate.max')),
        plantStatus: Yup.string()
            .required(t('PlantsModal.validation.plantStatus.required')),
        /*infectedDate: Yup.date()
            .when('plantStatus', (plantStatus, schema) => {
                return plantStatus === 'infected' ? schema
                    .required('Infected date is required when plant status is infected')
                    .min(Yup.ref('plantedDate'), 'Infected date must be after planted date')
                    .max(new Date(), 'Infected date cannot be in the future')
                    : schema.notRequired();
            }),*/
        infectedReason: Yup.string().when('infectedReason', {
            is: '',
            then: () => Yup.string().notRequired(),
            otherwise: () => (Yup.string()
                .min(3, t('PlantsModal.validation.infectedReason.min', { min: '3' }))
                .max(32, t('PlantsModal.validation.infectedReason.max', { max: '100' }))
                .matches(/^[\w\s-\.'",]+$/, t('PlantsModal.validation.infectedReason.matches'))
                .required()),
        }),
        /*plantDeadDate: Yup.date()
            .typeError(t('PlantsModal.validation.plantDeadDate.typeError'))
            .min(Yup.ref('plantedDate'), t('PlantsModal.validation.plantDeadDate.min'))
            .max(new Date(), t('PlantsModal.validation.plantDeadDate.max'))
            .notRequired(),*/
        deadReason: Yup.string().when('deadReason', {
            is: '',
            then: () => Yup.string().notRequired(),
            otherwise: () => (Yup.string()
                .min(3, t('PlantsModal.validation.deadReason.min', { min: '3' }))
                .max(32, t('PlantsModal.validation.deadReason.max', { max: '100' }))
                .matches(/^[\w\s-\.'",]+$/, t('PlantsModal.validation.deadReason.matches'))
                .required()),
        }),
        surveyNumber: Yup.string()
            .required(t('PlantsModal.validation.surveyNumber.required')),
        blockNumber: Yup.string()
            .required(t('PlantsModal.validation.blockNumber.required')),
        vendorName: Yup.string().when('vendorName', {
            is: '',
            then: () => Yup.string().notRequired(),
            otherwise: () => (Yup.string()
                .min(3, t('PlantsModal.validation.vendorName.min', { min: '3' }))
                .max(32, t('PlantsModal.validation.vendorName.max', { max: '50' }))
                .matches(/^[\w\s-\.]+$/, t('PlantsModal.validation.vendorName.matches'))
                .required()),
        }),
        plantCost: Yup.number()
            .typeError(t('PlantsModal.validation.plantCost.typeError'))
            .positive(t('PlantsModal.validation.plantCost.positive'))
            .integer(t('PlantsModal.validation.plantCost.integer'))
            .min(0, t('PlantsModal.validation.plantCost.min', { min: '0' }))
            .max(5000, t('PlantsModal.validation.plantCost.max', { max: '5000' }))
            .notRequired(),
        latitude: Yup.number()
            .notRequired()
            .typeError(t('PlantsModal.validation.latitude.typeError'))
            .when('latitude', (latitude, schema) => {
                // Handle undefined case and 0
                let lat = latitude[0];
                if (lat && lat > 0) {
                    return schema
                        .min(10.39, t('PlantsModal.validation.latitude.min', { min: '10.39', max: '10.40' }))
                        .max(10.40, t('PlantsModal.validation.latitude.max', { min: '10.39', max: '10.40' }))

                } else {
                    return schema.notRequired();
                }
            }),
        longitude: Yup.number()
            .notRequired()
            .typeError(t('PlantsModal.validation.longitude.typeError'))
            .when('longitude', (longitude, schema) => {
                // Handle undefined case and 0
                let lon = longitude[0];
                if (lon && lon > 0) {
                    return schema
                        .min(77.79, t('PlantsModal.validation.longitude.min', { min: '77.79', max: '77.81' }))
                        .max(77.81, t('PlantsModal.validation.longitude.max', { min: '77.79', max: '77.81' }))

                } else {
                    return schema.notRequired();
                }
            }),
        waterFrequency: Yup.string(),
        waterRequirement: Yup.number()
            .typeError(t('PlantsModal.validation.waterRequirement.typeError'))
            .min(0, t('PlantsModal.validation.waterRequirement.min', { min: '0' }))
            .max(100, t('PlantsModal.validation.waterRequirement.max', { max: '100' }))
            .notRequired(),
        /*lastPruned: Yup.date()
            .notRequired()
            .typeError(t('PlantsModal.validation.lastPruned.typeError'))
            .min(Yup.ref('plantedDate'), t('PlantsModal.validation.lastPruned.min'))
            .max(new Date(), t('PlantsModal.validation.lastPruned.max')),*/
        pruneEffort: Yup.number()
            .typeError(t('PlantsModal.validation.pruneEffort.typeError'))
            .min(0, t('PlantsModal.validation.pruneEffort.min', { min: '0' }))
            .max(480, t('PlantsModal.validation.pruneEffort.max', { max: '480' }))
            .notRequired(),
        fertigationFrequency: Yup.string(),
        /*lastFertilised: Yup.date()
            .typeError(t('PlantsModal.validation.lastFertilised.typeError'))
            .min(Yup.ref('plantedDate'), t('PlantsModal.validation.lastFertilised.min'))
            .max(new Date(), t('PlantsModal.validation.lastFertilised.max'))
            .notRequired(),*/
        fertileEffort: Yup.number()
            .typeError(t('PlantsModal.validation.fertileEffort.typeError'))
            .min(0, t('PlantsModal.validation.fertileEffort.min', { min: '0' }))
            .max(480, t('PlantsModal.validation.fertileEffort.max', { max: '480' }))
            .notRequired(),
        harvestFrequency: Yup.string(),
        harvestEffort: Yup.number()
            .typeError(t('PlantsModal.validation.harvestEffort.typeError'))
            .min(0, t('PlantsModal.validation.harvestEffort.min', { min: '0' }))
            .max(480, t('PlantsModal.validation.harvestEffort.max', { max: '480' }))
            .notRequired(),        
        /*lastHarvested: Yup.date()
            .nullable()
            .typeError(t('PlantsModal.validation.lastHarvested.typeError'))
            .min(Yup.ref('plantedDate'), t('PlantsModal.validation.lastHarvested.min'))
            .max(new Date(), t('PlantsModal.validation.lastHarvested.max'))
            .notRequired(),*/
        note: Yup.string().when('note', {
            is: '',
            then: () => Yup.string().notRequired(),
            otherwise: () => (Yup.string()
                .min(4, t('PlantsModal.validation.note.min', { min: '4' }))
                .max(200, t('PlantsModal.validation.note.max', { max: '200' }))
                .matches(/^[\w\s-\.,'"]+$/, t('PlantsModal.validation.note.matches'))
            ),
        }),
    }, [
        ["biologicalName", "biologicalName"],   
        ["otherName", "otherName"],
        ["vendorName", "vendorName"],
        ["infectedReason", "infectedReason"],
        ["deadReason", "deadReason"],
        ["latitude", "latitude"],
        ["longitude", "longitude"],
        ["note", "note"],
    ] // Cyclic dependency
    );