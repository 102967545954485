import React, { Component } from 'react';
import dataService from '../services/data.service';

export default class CollectionDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options:[]
        };
    }

    /**
     * Set the state with collection list
     * @param {object} data collection list
     */
    setCollectionsData = (data) => {
        const options = data.map((option) => {
            return <option key={option.label} value={option.cname}>{option.label}</option>;
        });
        this.setState({ options });
    }    

    handleCollectionSelect = cname => {
        this.setState({ selectedCollection: cname });
    };

    componentDidMount() {
        this.setCollectionsData(dataService.COLLECTION_LIST_DEV); // Set collection list
    }    

    render() {
        const { selectedCollection, options } = this.state;
        return (
            <>
                <div className="input-group">
                    <span className="input-group-text text-narbhavi">
                        <i className='fa-sharp fa-solid fa-list'></i>
                    </span>
                    <select className="form-select" id="collectionList" name="collectionlist" defaultValue="" onChange={this.props.selectedCollection}>
                        <option value="">Select a collection</option>
                        {options}
                    </select>
                </div>
            </>
        )
    }
}
