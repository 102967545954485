import React, { useEffect } from 'react';
import './messageprompt.module.css';

export default function MessagePrompt(props) {
  const { title, modalId, iconClass, message, textCancel, textOk, handleButtonCancel, handleButtonOk } = props;

  useEffect(() => {
    props.initModal(modalId);
  }, [props, modalId]);

  return (
    <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id={modalId} aria-labelledby="Delete Prompt" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleButtonCancel}></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-2 align-self-center">
                  <i className={iconClass}></i>
                </div>
                <div className="col-10">
                  {message}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleButtonCancel}>{textCancel}</button>
            <button type="button" className="btn btn-primary" onClick={handleButtonOk}>{textOk}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
