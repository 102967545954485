import React, { Component } from 'react';

// create a new context for alerts
const AlertContext = React.createContext();

class AlertProvider extends Component {
  constructor(props) {
    super(props);

    // initialize the alertsQueue state
    this.state = {
      alertsQueue: []
    };

    // bind the addAlert method to this instance
    this.addAlert = this.addAlert.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
  }

  addAlert(message, type = "success") {
    const id = Math.random().toString(36).substring(2, 11);
    this.setState((state) => ({
        alertsQueue: [...state.alertsQueue, { id, message, type }],
    }));
    setTimeout(() => this.removeAlert(id), 3000);
  }

  removeAlert(id) {
    this.setState((state) => ({
        alertsQueue: state.alertsQueue.filter((alert) => alert.id !== id),
    }));
  }

  render() {
    // create the context value with the alertsQueue state and the addAlert method
    const alertContextValue = {
      alertsQueue: this.state.alertsQueue,
      addAlert: this.addAlert,
      removeAlert: this.removeAlert
    };

    // return the AlertContext.Provider with the alertContextValue as its value
    return (
      <AlertContext.Provider value={alertContextValue}>
        {this.props.children}
      </AlertContext.Provider>
    );
  }
}

export { AlertProvider, AlertContext };