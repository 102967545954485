import React from 'react';
import './home.css';

export default function Home() {
  return (
    <>
      <main className='p-0 main'>
        <section className='expanding-column'>
          <div id="carouselExampleIndicators" className="carousel slide" data-pause="true" data-bs-ride="carousel">
            <div className='m-0 p-0'>
              <div className="carousel-inner c1">
                <div className="carousel-item active">
                  <img src="/carousel1.jpg" className="mx-auto d-block" alt="..." />
                  <div className="carousel-caption grad d-none d-md-block">
                    <h2>Our Farm</h2>
                    <p>At Narbhavi we practice Organic farming by using innovative and smart technologies. We continuosly research, experiment and implement our ideas.<br></br>
                      We provide various consulting services in design and planning of the farm, implementing agriculture technologies, automating and reducing the workforce.</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="/carousel2.jpg" className="mx-auto d-block" alt="..." />
                  <div className="carousel-caption grad d-none d-md-block">
                    <h2>Rental Services</h2>
                    <p>We provide a leasing opportunity for agricultural land, perfect for a specified duration, and well-suited for a range of cultivation needs, whether it's for sowing seeds or nurturing medicinal plants. To assist farmers, we offer vital resources like water and organic fertilizers sourced from our own farm.</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="/carousel3.jpg" className="mx-auto d-block" alt="..." />
                  <div className="carousel-caption grad d-none d-md-block">
                    <h2>Research</h2>
                    <p>Agriculture presents us with a multitude of challenges that necessitate thorough research and innovative solutions. These challenges encompass Water Conservation, Natural Pest Control, Yield Enhancement, Wildlife Intrusion Prevention and more.</p>
                  </div>
                </div>
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <aside>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Rent a Farm</h5>
            <p class="card-text">Embrace the farmer’s life with us as we rent out small plots of land from farms around Kannivadi hills for you to grow veggies.</p>
            <a href="#" class="btn btn-primary">Read more...</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Neem Products</h5>
            <p class="card-text">Neem powder natural may act as a body cleanser, blood purifier, support immunity, and liver health.</p>
            <a href="#" class="btn btn-primary">Read more...</a>
          </div>
        </div>
      </aside>
    </>
  );
}
