import React, { useState } from 'react';

function DropdownCheckbox(props) {
  const [coloumnHeaders, setColoumnHeaders] = useState(props.headers);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen((prevState) => !prevState.dropdownOpen);
  };

  const handleCheckedElement = (event, option) => {
    const updatedOptions = coloumnHeaders.map((item) => {
      if (item.key === option.key) {
        return { ...item, isChecked: event.target.checked };
      }
      return item;
    });

    setColoumnHeaders(updatedOptions);
    setSelectedOptions(updatedOptions);
    setDropdownOpen(true);
    props.onChildValueChange(updatedOptions);
  };

  const handleOptionSelect = () => {
    const selectedOptions = coloumnHeaders.filter((option) => option.isChecked);
    setSelectedOptions(selectedOptions);
    setDropdownOpen(false);
  };

  const menuwidth = props.menuwidth;

  return (
    <div className="btn-group">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-bs-auto-close="outside"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        aria-expanded={dropdownOpen}
        onClick={handleDropdownToggle}
      >
        <span className="me-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-layout-three-columns" viewBox="0 0 16 16">
            <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5H5V1H1.5zM10 15V1H6v14h4zm1 0h3.5a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H11v14z" />
          </svg>
        </span>
      </button>
      <ul className="dropdown-menu dropdown-menu-lg-end" style={{ overflowY: "scroll", height: "200px", width: menuwidth }} aria-labelledby="dropdownMenuButton">
        {coloumnHeaders.map((option) => (
          <li key={option.key}>
            <div className="dropdown-item w-auto">
              <label htmlFor={`option-${option.key}`}>
                {option.value}
              </label>
              <input
                type="checkbox"
                className="m-2 float-end"
                id={`option-${option.key}`}
                onChange={(event) => handleCheckedElement(event, option)}
                checked={option.isChecked}
                value={option.key}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DropdownCheckbox;
