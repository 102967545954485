import React, { Component } from 'react'

export default class Contact extends Component {
  render() {
    return (
      <div className='main'>
        <h3>Contact</h3>
        <p>
          This page describes about the ways to contact us including the email id.
        </p>
      </div>
    )
  }
}
