import React, { Component } from 'react';
import { Outlet, Navigate } from "react-router-dom";
import UserAuthContext from './UserAuthProvider';

export default class ProtectedRoutes extends Component {
    isAuthenticated = false;
    static contextType = UserAuthContext;

    render() {
        const isAuthenticated = this.context.isAuthenticated;
        const roleName = this.context.userInfo.roleName;
        const {allowedRoles} = this.props;
        let isAllowed = false;
        if (isAuthenticated && allowedRoles) {
            if (allowedRoles.find(rName => rName === roleName.toUpperCase())) {
                isAllowed = true;
            }
        }
        return (
            isAllowed ? <Outlet /> : <Navigate to="/" replace />
        );
    }
}