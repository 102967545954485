import React, { Component, createContext, createRef } from 'react'
import { authenticate, provider, signByRedirect, persistence, sessionPersistence } from '../config/config';
import { db } from '../config/config';
import Dashboard from '../pages/admin/dashboard/Dashboard';
import { collection, onSnapshot, getDocs, query, where, doc, getDoc } from "firebase/firestore";

const UserAuthContext = createContext();
export const UserAuthConsumer = UserAuthContext.Consumer;

export class UserAuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { "userInfo": {}, isAuthenticated: false };
    this.userInfo = {};

    /*
    const users = onSnapshot(userAccounts, (snapshot) => {
        let userList = [];
        snapshot.docs.forEach((doc) => {
            userList.push({ ...doc.data() });
            this.verifyUser(userList[0].id);
        })
    }, (error) => {
        console.error(error);
    });
    */
  }

  /**
   * Allow signin using Google signin verfication
   */
  signInWithGoogle = (isAuthenticated) => {
    if (!isAuthenticated) {
      persistence(authenticate, sessionPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          signByRedirect(authenticate, provider);
        })
        .catch((error) => {
          // Handle Errors here.
          console.log("Authentication Error Code: ", error.code);
          console.log("Authentication Error Message: ", error.message);
        });
    }
  };

  /**
   * Verify whether the user is present in the user table and fetch
   * the defined role.
   * @param {object} user - contains user info returned by firebase 
   */
  verifyUser = (async (id) => {
    try {
      if (id) {
        const queryResult = query(collection(db, "user_account"), where("id", "==", id));
        const querySnapshot = await getDocs(queryResult);
        if (querySnapshot.size === 1) {
          querySnapshot.forEach((doc) => {
            this.setState({ "userInfo": doc.data(), isAuthenticated: true }, () => {
              // TODO: As of now no solution for class basec component to 
              // redirect to the dashboard page. Tomorrow if something is added
              // then we can redirect to Dashboard page from here.
            });
          });
        } else {
          this.setState({ "userInfo": {}, isAuthenticated: false });
        }
      }
    } catch (error) {
      console.error(error);
    }
  });

  /**
   * Sign out user
   */
  signOut = () => {
    authenticate.signOut()
      .then(() => {
        this.setState({ isAuthenticated: false });
        console.log("Signed out");
      })
      .catch((errorObj) => {
        console.error(errorObj);
      })
  }

  componentDidMount = () => {
    authenticate.onAuthStateChanged((user) => {
      if (user) {
        this.verifyUser(user.uid);
      }
    }).bind(this);

    document.addEventListener("signin", (event) => {
      // this is a custom event and we don't need any browser default events to trigger
      event.preventDefault();
      this.signInWithGoogle();
    });
  }

  componentWillUnmount() {
    document.removeEventListener("signin", {});
  }

  render() {
    const userInfo = this.state.userInfo;
    const isAuthenticated = this.state.isAuthenticated;
    const { signInWithGoogle, signOut } = this;
    return (
      <UserAuthContext.Provider value={{ isAuthenticated, signInWithGoogle, signOut, userInfo }}>
        {this.props.children}
      </UserAuthContext.Provider>
    )
  };
}

export default UserAuthContext;