import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import App from './App';
import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import i18n from './i18n'; //required for translation to work in other pages

const root = ReactDOM.createRoot(document.getElementById('root'));
window.jQuery = $;
window.$ = $;
window.bootstrap = bootstrap;

root.render(
    <App />
);
